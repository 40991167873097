import React, { useEffect, useState } from "react";

const NavigationBar = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstallable, setIsInstallable] = useState(false);
    const message = "ホーム画面に追加";
    const disabledMessage = "ホーム画面に追加済み";

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (e) => {
        setDeferredPrompt(e);
        setIsInstallable(true);
        });
    }, []);

    const onInstallClick = async () => {
        if (!deferredPrompt) {
        return;
        }

        deferredPrompt.prompt();
        const choiceResult = await deferredPrompt.userChoice;
        if (choiceResult.outcome === "accepted") {
        setDeferredPrompt(null);
        setIsInstallable(false);
        } else {
        setIsInstallable(true);
        }
    };

    const onAboutExpAInterClick = () => {
        window.open("https://vl.araya.org/painter", "_blank");
    };
    const onAboutUsClick = () => {
        window.open("https://vl.araya.org/about-for-expainter-webapp", "_blank");
    };
    const onShareClick = () => {
        window.open("http://twitter.com/intent/tweet?url=https://expainter.vl.araya.org/&text=&hashtags=ExpAInter", "_blank");
    };

    if(isInstallable) {
        return (
            <div class="fixed bottom-0 left-0 z-50 w-full h-20 bg-black border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600" style={{maxHeight: "7vh"}}>
                <div class="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
                    <button type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group" onClick={onShareClick}>
                        <svg class="w-5 h-5 mb-2 text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 1200 1227">
                            <path d="m714.163 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.2396h162.604l304.797 435.9906 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z"/>
                        </svg>
                        <span class="text-sm text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">共有</span>
                    </button>
                    <button type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group" onClick={() => void onInstallClick()} disabled={!isInstallable}>
                        <svg class="w-5 h-5 mb-2 text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 502.664 502.664">
                            <path d="M382.881,198.732V56.666c0-22.822-18.68-41.502-41.524-41.502H41.524
                                C18.68,15.164,0,33.845,0,56.666V356.5c0,22.822,18.68,41.502,41.524,41.502h181.173c21.981,52.568,73.837,89.497,134.364,89.497
                                c80.416,0,145.603-65.187,145.603-145.603C502.664,270.303,450.959,210.919,382.881,198.732z M213.076,362.432H31.429V101.232
                                h320.024v95.343c-77.784,2.977-139.973,66.783-139.973,145.322C211.458,348.886,212.127,355.724,213.076,362.432z
                                M437.477,370.306h-53.884v53.884h-53.043v-53.884h-53.884v-53.043h53.884v-53.884h53.043v53.884h53.884V370.306z"/>
                            {/* <path d="M11.074 4 8.442.408A.95.95 0 0 0 7.014.254L2.926 4h8.148ZM9 13v-1a4 4 0 0 1 4-4h6V6a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-2h-6a4 4 0 0 1-4-4Z"/>
                            <path d="M19 10h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1Zm-4.5 3.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12.62 4h2.78L12.539.41a1.086 1.086 0 1 0-1.7 1.352L12.62 4Z"/> */}
                        </svg>
                        <span class="text-sm text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">{isInstallable ? message : disabledMessage}</span>
                    </button>
                    <button type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group" onClick={onAboutExpAInterClick}>
                        <svg class="w-5 h-5 mb-2 text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 100 100">
                            <path d="M50.863,18.539c-17.92,0-32.5,14.579-32.5,32.5s14.58,32.5,32.5,32.5c17.92,0,32.5-14.579,32.5-32.5
                                S68.783,18.539,50.863,18.539z M50.863,79.539c-15.715,0-28.5-12.785-28.5-28.5s12.785-28.5,28.5-28.5s28.5,12.785,28.5,28.5
                                S66.578,79.539,50.863,79.539z"/>
                            <circle cx="51.381" cy="38.08" r="3.345"/>
                            <path d="M50.5,46.881c-1.104,0-2,0.896-2,2v17c0,1.104,0.896,2,2,2s2-0.896,2-2v-17C52.5,47.776,51.604,46.881,50.5,46.881z"/>
                        </svg>
                        <span class="text-sm text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">ExpAInterについて</span>
                    </button>
                    <button type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group" onClick={onAboutUsClick}>
                        <svg class="w-5 h-5 mb-2 text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                            <path d="M251.256,237.591c37.166,0,67.042-30.048,67.042-66.977c0.043-37.037-29.876-66.999-67.042-66.999 c-36.908,0-66.869,29.962-66.869,66.999C184.387,207.587,214.349,237.591,251.256,237.591z"/> <path d="M305.032,248.506H197.653c-19.198,0-34.923,17.602-34.923,39.194v107.854c0,1.186,0.604,2.243,0.669,3.473h175.823 c0.129-1.229,0.626-2.286,0.626-3.473V287.7C339.912,266.108,324.187,248.506,305.032,248.506z"/> <path d="M431.588,269.559c29.832,0,53.754-24.008,53.754-53.668s-23.922-53.711-53.754-53.711 c-29.617,0-53.582,24.051-53.582,53.711C377.942,245.53,401.972,269.559,431.588,269.559z"/> <path d="M474.708,278.317h-86.046c-15.445,0-28.064,14.107-28.064,31.472v86.413c0,0.928,0.453,1.812,0.518,2.826h141.03 c0.065-1.014,0.496-1.898,0.496-2.826v-86.413C502.707,292.424,490.11,278.317,474.708,278.317z"/> <path d="M71.011,269.559c29.789,0,53.733-24.008,53.733-53.668S100.8,162.18,71.011,162.18c-29.638,0-53.603,24.051-53.603,53.711 S41.373,269.559,71.011,269.559L71.011,269.559z"/> <path d="M114.109,278.317H27.977C12.576,278.317,0,292.424,0,309.789v86.413c0,0.928,0.453,1.812,0.539,2.826h141.03 c0.065-1.014,0.475-1.898,0.475-2.826v-86.413C142.087,292.424,129.489,278.317,114.109,278.317z"/>
                        </svg>
                        <span class="text-sm text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">私たちについて</span>
                    </button>
                </div>
            </div>
        )
    } else {
        return (
            <div class="fixed bottom-0 left-0 z-50 w-full h-20 bg-black border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
                <div class="grid h-full max-w-lg grid-cols-3 mx-auto font-medium">
                    <button type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group" onClick={onShareClick}>
                        <svg class="w-5 h-5 mb-2 text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 1200 1227">
                            <path d="m714.163 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.2396h162.604l304.797 435.9906 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z"/>
                        </svg>
                        <span class="text-sm text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">共有</span>
                    </button>
                    {/* <button type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group" onClick={() => void onInstallClick()} disabled={!isInstallable}>
                        <svg class="w-5 h-5 mb-2 text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M11.074 4 8.442.408A.95.95 0 0 0 7.014.254L2.926 4h8.148ZM9 13v-1a4 4 0 0 1 4-4h6V6a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h17a1 1 0 0 0 1-1v-2h-6a4 4 0 0 1-4-4Z"/>
                            <path d="M19 10h-6a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1Zm-4.5 3.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM12.62 4h2.78L12.539.41a1.086 1.086 0 1 0-1.7 1.352L12.62 4Z"/>
                        </svg>
                        <span class="text-sm text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">{isInstallable ? message : disabledMessage}</span>
                    </button> */}
                    <button type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group" onClick={onAboutExpAInterClick}>
                        <svg class="w-5 h-5 mb-2 text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 100 100">
                            <path d="M50.863,18.539c-17.92,0-32.5,14.579-32.5,32.5s14.58,32.5,32.5,32.5c17.92,0,32.5-14.579,32.5-32.5
                                S68.783,18.539,50.863,18.539z M50.863,79.539c-15.715,0-28.5-12.785-28.5-28.5s12.785-28.5,28.5-28.5s28.5,12.785,28.5,28.5
                                S66.578,79.539,50.863,79.539z"/>
                            <circle cx="51.381" cy="38.08" r="3.345"/>
                            <path d="M50.5,46.881c-1.104,0-2,0.896-2,2v17c0,1.104,0.896,2,2,2s2-0.896,2-2v-17C52.5,47.776,51.604,46.881,50.5,46.881z"/>
                        </svg>
                        <span class="text-sm text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">ExpAInterについて</span>
                    </button>
                    <button type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group" onClick={onAboutUsClick}>
                        <svg class="w-5 h-5 mb-2 text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                            <path d="M251.256,237.591c37.166,0,67.042-30.048,67.042-66.977c0.043-37.037-29.876-66.999-67.042-66.999 c-36.908,0-66.869,29.962-66.869,66.999C184.387,207.587,214.349,237.591,251.256,237.591z"/> <path d="M305.032,248.506H197.653c-19.198,0-34.923,17.602-34.923,39.194v107.854c0,1.186,0.604,2.243,0.669,3.473h175.823 c0.129-1.229,0.626-2.286,0.626-3.473V287.7C339.912,266.108,324.187,248.506,305.032,248.506z"/> <path d="M431.588,269.559c29.832,0,53.754-24.008,53.754-53.668s-23.922-53.711-53.754-53.711 c-29.617,0-53.582,24.051-53.582,53.711C377.942,245.53,401.972,269.559,431.588,269.559z"/> <path d="M474.708,278.317h-86.046c-15.445,0-28.064,14.107-28.064,31.472v86.413c0,0.928,0.453,1.812,0.518,2.826h141.03 c0.065-1.014,0.496-1.898,0.496-2.826v-86.413C502.707,292.424,490.11,278.317,474.708,278.317z"/> <path d="M71.011,269.559c29.789,0,53.733-24.008,53.733-53.668S100.8,162.18,71.011,162.18c-29.638,0-53.603,24.051-53.603,53.711 S41.373,269.559,71.011,269.559L71.011,269.559z"/> <path d="M114.109,278.317H27.977C12.576,278.317,0,292.424,0,309.789v86.413c0,0.928,0.453,1.812,0.539,2.826h141.03 c0.065-1.014,0.475-1.898,0.475-2.826v-86.413C142.087,292.424,129.489,278.317,114.109,278.317z"/>
                        </svg>
                        <span class="text-sm text-white dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500">私たちについて</span>
                    </button>
                </div>
            </div>
        )
    }
}

export default NavigationBar;