import Config from "./config";

const OutOfDate = () => {
    var imgStyle = {
        widthMax: "1080px",
        heightMax: "1080px",
        // widthMax: "640px",
        // widthMax: "100%",
        // aspectRatio: "1 / 1",
        // position: "absolute",
        objectFit: "cover",
        // objectViewBox: "inset(0px 0px 32px 32px)",
        // aspectRatio: "1 / 1",
    }
    imgStyle = Object.assign(imgStyle, Config.ImageStyle);
    return (
      <div className="Container">
        <div className="w-screen flex justify-center items-center relative">
            <h1 className="text-white text-3xl mt-3">平日の9時～18時のみ使用できます。</h1>
        </div>
        <div className="w-screen flex justify-center items-center relative">
            <img src="vl.gif" alt="vl" style={imgStyle}/>
        </div>
        <div className="h-20"></div>
      </div>
    );
};

export default OutOfDate;