import React, { useState, useRef, useCallback } from "react";
import termsAndConditions from "./termsAndConditions";
import Config from "./config";

const TextAreaWithBottomSensor = (props) => {
  const textAreaRef = useRef(null);
  const scrollCallback = useCallback(() => {
    const elem = textAreaRef.current;
    if(Math.abs(elem.scrollHeight - elem.clientHeight - elem.scrollTop) < 1){
      onBottom();
    }
  });
  const onBottom = (() => {
    if(props.onBottom !== undefined){
      props.onBottom();
    }
  });
  // const textAreaStyle = {
  //   width: "1080px",
  // }
  return(
    <textarea
      className={props.className}
      value={termsAndConditions}
      readOnly
      onScroll={scrollCallback}
      ref={textAreaRef}
      style={props.style}
      // style={textAreaStyle}
    />
  )

}

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};


const TermsAndConditions = (props) => {
  // return <MainApp />;
  const [isAgreed, setIsAgreed] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const callback = (event) => {
    setIsRead(true);
  };
  const onNext = (event) => {
    console.log("onNext isAgreed:", isAgreed);
    if(props.onNext !== undefined){
        props.onNext(isAgreed);
        console.log("bbbbbbb:", isAgreed);
    } else {
        console.log("aaaaaaa:", isAgreed);
    };
  };
  const isAgreedCallback = (event) => {
    setIsAgreed(event.target.checked);
  }
  const [width, height] = useWindowSize();
  const textAreaWidth = width < Config.ScreenWidth ? width + "px": Config.ScreenWidth;
  const textAreaStyle = {
    width: textAreaWidth,
    height: 40 + "vh",
  }
  console.log("isAgreed:", isAgreed);
  return (
    <div className="w-screen flex justify-center">
      <div className="flex-col">
        <p className="text-white text-center text-4xl m-3">利用規約</p>
        <div className="">
          <TextAreaWithBottomSensor onBottom={callback} style={textAreaStyle} className="text-white bg-gray-800"/>
        </div>
        <div className="flex justify-center">
          <input type="checkbox" className="scale-150" value={isAgreed} onChange={isAgreedCallback} disabled={!isRead}/>
          <label for="scales" className="text-white text-2xl ml-4">同意する</label>
        </div>k
        <div className="flex justify-center">
          <br/>
          <button onClick={onNext} class="bg-black hover:bg-white text-white font-semibold hover:text-black py-2 px-4 border border-gray-300 rounded">Next</button>
        </div>
      </div>
    </div>
  )
}
export default TermsAndConditions;