const UserAuth = false;
const ScreenWidth = 1080;
const ScreenHeight = 1080;

const Config = {
    UserAuth,
    ScreenHeight,
    ScreenWidth,
    ImageStyle: {
        maxHeight: "75vh",
    },
}
export default Config;
