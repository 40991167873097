import React, {useRef, useEffect, useState} from "react";
import styled, { keyframes } from "styled-components";
import Config from "./config";

class ClickableImageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClicked = props.handleClicked;
    this.animationFinished = props.animationFinished;
    this.src = props.src;
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseClicked = this.handleUp.bind(this);
    this.state = { x: 0, y: 0, top:0, bottom:0, left:0, right:0, x_ratio:0, y_ratio:0, animation: false, clickX: 0, clickY: 0};
    this.animationDuration = 1000;
  }

  handleMouseMove = (event) => {
    // console.log(event.target);
    var bounds = event.target.getBoundingClientRect();
    var width = bounds.right - bounds.left;
    var height = bounds.bottom - bounds.top;
    var modifiedX = event.clientX - bounds.left;
    var modifiedY = event.clientY - bounds.top;
    var x_ratio = modifiedX / width;
    var y_ratio = modifiedY / height;

    this.setState({
        x: modifiedX,
        y: modifiedY,
        top: bounds.top,
        bottom: bounds.bottom,
        left: bounds.left,
        right: bounds.right,
        x_ratio: x_ratio,
        y_ratio: y_ratio,
        animation: this.state.animation,
    });
  }
  animationDone = () => {
    // console.log("animation finished");
    this.animationFinished();
  }

  handleUp = (event) => {
    if(this.state.animation === true){
      return
    };
    var bounds = event.target.getBoundingClientRect();
    var width = bounds.right - bounds.left;
    var height = bounds.bottom - bounds.top;
    var modifiedX = event.clientX - bounds.left;
    var modifiedY = event.clientY - bounds.top;
    var x_ratio = modifiedX / width;
    var y_ratio = modifiedY / height;
    this.handleClicked({x: x_ratio, y: y_ratio});
    this.setState({
        x: modifiedX,
        y: modifiedY,
        top: bounds.top,
        bottom: bounds.bottom,
        left: bounds.left,
        right: bounds.right,
        x_ratio: x_ratio,
        y_ratio: y_ratio,
        animation: true,
        clickX: modifiedX,
        clickY: modifiedY,
    });
    setTimeout(this.animationDone, this.animationDuration);
  }



  render() {
    // console.log(this.state.x, this.state.y)
    // const obj = document.getElementById("imgArea");
    // var bounds = obj.getBoundingClientRect();
    // console.log(bounds);

    var style2_pos_x = -10 * (this.state.x_ratio - 0.5) + 50
    var style2_pos_y = -10 * (this.state.y_ratio - 0.5) + 50
    const style2 = {
        transform: `translate(-${style2_pos_x}%, -${style2_pos_y}%)`,
        position: "absolute",
        top: "50%",
        left: "50%",
        fontSize: "2vh",
        padding: "0px",
        margin: "0px",
        opacity: "0.7",
        // pointerEvent: "none",
        // userSelect: "none",
    };
    // const mask = `radial-gradient(circle, black 50%, rgba(0, 0, 0, 0.5) 50%)`
    const mask = `radial-gradient(at ${this.state.x}px ${this.state.y}px, black 00%, rgba(0, 0, 0, 0.4) 50%)`
    var imageStyle = {
      maskImage: mask,
      WebkitMaskImage: mask,
      // maskPosition: `${this.state.x}px ${this.state.y}px`,
      // WebkitMaskPosition: `${this.state.x}px ${this.state.y}px`,
      maskRepeat: "no-repeat",
      WebkitMaskRepeat: "no-repeat",
    }
    imageStyle = Object.assign(imageStyle, Config.ImageStyle);
    const waveKeyframes = keyframes`
      0% {
        top: ${this.state.clickY}px;
        left: ${this.state.clickX}px;
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      };
      100% {
        top: ${this.state.clickY}px;
        left: ${this.state.clickX}px;
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
      };
    `;
    const WaveStyle = styled.div`
      position: absolute;
      top: ${this.state.clickY}px;
      left: ${this.state.clickX}px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: skyblue;/*背景色*/
      transform: translate(-50%, -50%);
      animation: ${waveKeyframes} 0.5s linear infinite;
    `;
    const dummyStyle = {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0px",
      left: "0px",
    };

    if(this.state.animation === true){
      return (
          <div class="relative">
              <img style={imageStyle} src={this.src} alt="screenshot" id="imgArea"/>
              <div style={style2}>
                  <p>塗りたい部分をタップ</p>
              </div>
              <WaveStyle />
          </div>
      );
    } else {
      return (
          <div class="relative">
              <img style={imageStyle} src={this.src} alt="screenshot" id="imgArea"/>
              <div style={style2}>
                  <p disabled>塗りたい部分をタップ</p>
              </div>
              <div style={dummyStyle} onMouseMove={this.handleMouseMove} onClick={this.handleUp} ></div>
          </div>
      );
    }
  }
}


const ClickableImage = (props) => {
    // const [bounds, setBounds] = useState({x: 0, y: 0, width: 0, height: 0});

    // useEffect(() => {
    //     const obj = document.getElementById("imgArea");
    //     var bounds = obj.getBoundingClientRect();
    //     console.log(bounds);
    //     setBounds({
    //         x: bounds.left,
    //         y: bounds.top,
    //         width: bounds.right - bounds.left,
    //         height: bounds.bottom - bounds.top,
    //     });
    // }, [setBounds]);


    return (
      <div className="text-white">
        <div>
            <ClickableImageComponent src={props.src} handleClicked={props.handleClicked} animationFinished={props.animationFinished}/>
        </div>
      </div>
    )
};

export default ClickableImage;