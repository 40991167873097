import styled, {keyframes} from "styled-components"
import { useCallback, useState } from "react";
import Config from "./config";

function getUserType() {
    var ua = [
        'iPod',
        'iPad',
        'iPhone',
        'Android',
    ];

  for (var i = 0; i < ua.length; i++) {
    if (navigator.userAgent.indexOf(ua[i]) > 0) {
        return ua[i];
    }
  }
  return 'Other';
};

function judgeiOS() {
    var ua = getUserType();
    if(ua === 'iPod' || ua === 'iPad' || ua === 'iPhone'){
        return true;
    } else {
        return false;
    }
};


const ServeImageComponents = (props) => {
    const [servImgFront, setServImgFront] = useState(true);
    const img = props.img;
    const servImg = props.servImg;
    const switchImage = useCallback(() => {
        setServImgFront(!servImgFront);
    })


    const onHandleDownload = (event) => {
        const imageUrls = [
            img,
            servImg,
        ];
        const currentTime = new Date();
        console.log("onHandleDownload");
        const nameDict = {
            0: currentTime.toISOString() + "-ExpAInter-original.png",
            1: currentTime.toISOString() + "-ExpAInter-art.png",
        }
        const iOS = judgeiOS();
        if (false) {
            Promise.all(imageUrls.map(imageUrl => fetch(imageUrl).then(response => response.blob())))
            .then(blobs => {
                const files = blobs.map((blob, index) => new File([blob], `${nameDict[index]}`, {type: 'image/png'}));
                if (navigator.share) {
                navigator.share({
                    files,
                    title: 'タイトル',
                    text: 'テキスト',
                });
                }
            });
        } else {
            imageUrls.forEach((imageUrl, index) => {
            fetch(imageUrl)
                .then(response => response.blob())
                .then(blob => {
                    const file = new File([blob], `${nameDict[index]}`, {type: 'image/png'});
                    const a = document.createElement('a');
                    a.href = URL.createObjectURL(file);
                    a.download = file.name;
                    a.click();
                });
            });
        }
    };


    const  reveal = keyframes`
    from {
        clip-path: polygon(0 0, 0 100%, 0% 100%, 0% 0);
    }
    to {
        clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    }
    `;
    const hide = keyframes`
    from {
        clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
    }
    to {
        clip-path: polygon(100% 0, 100% 100%, 100% 100%, 100% 0);
    }
    `;
    const STransAnimeNew = styled.img`
    animation: ${reveal} 2s linear;
    position: relative;
    `;
    const STransAnimeOld = styled.img`
    animation: ${hide} 2s linear;
    position: absolute;
    `;
    const currentTime = new Date();
    const nameDict = {
        0: currentTime.toISOString() + "-ExpAInter-original.png",
        1: currentTime.toISOString() + "-ExpAInter-art.png",
    }
    return (
        <div class="relative">
            <STransAnimeOld src={img} alt="screenshot" style={Config.ImageStyle}/>
            <STransAnimeNew src={servImg} alt="screenshot" style={Config.ImageStyle}/>
            {!servImgFront ? <img src={img} className="absolute top-0 left-0" style={Config.ImageStyle} />  : <div />}
            <div className="absolute bottom-0 right-0">
                <a href={img} download={nameDict[0]}>
                    <div  className="bg-blue-200 rounded-md opacity-80 cursor-pointer px-1 py-1 text-black">
                        <p>原画</p>
                        <img src="/download-backup-svgrepo-com.svg" style={{maxHeight: "3vh"}} ></img>
                    </div>
                </a>
                <a href={servImg} download={nameDict[1]}>
                    <div  className="bg-red-200 rounded-md opacity-80 cursor-pointer px-1 py-1 text-black">
                        <p>生成</p>
                        <img src="/download-backup-svgrepo-com.svg" style={{maxHeight: "3vh"}} ></img>
                    </div>
                </a>
                {/* <img src="/download-backup-svgrepo-com.svg" style={{maxHeight: "3vh"}} className="bg-white rounded-md opacity-80 cursor-pointer px-1 py-1" onClick={() => {onHandleDownload}}></img> */}
                {/* <button onClick={onHandleDownload} className="bg-black bg-opacity-40 px-1 py-1 hover:bg-opacity-70">DL</button> */}
            </div>
            <div className="absolute top-0 right-0">
                <div className="bg-white rounded-md opacity-80 cursor-pointer px-1 py-1 text-black">
                    <p>入替</p>
                    <img src="/reload-svgrepo-com.svg" style={{maxHeight: "3vh"}} onClick={switchImage}></img>
                </div>
                {/* <button onClick={switchImage} className="bg-black bg-opacity-40 px-1 py-1 hover:bg-opacity-70">{"<-->"}</button> */}
            </div>
        </div>
    )

};

export default ServeImageComponents;