/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import {Amplify,  Auth } from 'aws-amplify';

const awsmobile = {
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_MAcZ70BPA',
    userPoolWebClientId: '79bbmcd7pispuhk8ratho9mp7a',
  }
};


export default awsmobile;
