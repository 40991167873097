const termsAndConditions = `ExpAInter利用規約


株式会社アラヤ（以下「当社」といいます。）が、気鋭の画家の真田将太朗氏の協力を得て、提供する画像生成AIのwebアプリのサービス（「ExpAInter」、以下「本サービス」といいます。）を利用される場合の利用規約（以下「本規約」といいます。）を以下、定めます。本サービスを利用されえる方は、本規約を充分にお読みいただき、本規約に同意される場合にのみ本サービスの利用を開始してください。本サービスを利用された方は、本規約に同意したものと看做されますので、ご留意ください。

第１条	（目的）
この利用規約（以下「本規約」といいます。）は、本サービスを利用する全て利用者に適用されます。

第２条	（利用許諾）
当社は、本規約に同意した利用者にのみ、本サービスの利用を許諾するものとします。

第３条	（本サービスの内容）
本サービスは、利用者がアップロードした画像データを用いて新たな画像を生成し、利用者に対して生成された画像データを提供するサービスです。ただし、利用者は、第三者が著作権を有する画像データ、第三者の肖像、著作物、個人情報が写り込んだ画像データを本サービスの利用に際してアップロードしないものとします。

第４条　（禁止事項）
利用者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
(1) 法令に違反する行為または犯罪行為に関連する行為
(2) 当社、真田氏、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為
(3) 公序良俗に反する行為
(4) 当社、真田氏、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
(5) 本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること
  ・過度に暴力的または残虐な表現を含む情報
  ・コンピューターウィルスその他の有害なコンピューター・プログラムを含む情報
  ・当社、真田氏、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報
  ・過度にわいせつな表現を含む情報
  ・差別を助長する表現を含む情報
  ・自殺、自傷行為を助長する表現を含む情報
  ・反社会的な表現を含む情報
  ・チェーンメール等の第三者への情報の拡散を求める情報
  ・他人に不快感を与える表現を含む情報
(6) 本サービスのネットワークまたはシステム等に過度な負荷をかける行為
(7) リバースエンジニアリングその他の解析行為
(8) 本サービスの運営を妨害するおそれのある行為
(9) 当社のネットワークまたはシステム等への不正アクセス
(10) 第三者に成りすます行為
(11) 当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
(12) 反社会的勢力等への利益供与
(13) 前各号の行為を直接または間接に惹起し、または容易にする行為
(14) 前各号の行為を試みること
(15) その他、当社が不適切と判断する行為

第５条	（本サービスの停止等）
当社は、メンテナンス等のために、利用者に通知することなく、本サービスを停止又は変更することができるものとします。

第６条	（知的財産権）
１. 本サービス、webアプリ等に関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
２. 利用者が本サービスにアップロードした画像データ、および、当該画像データを基に本サービスによって生成された画像データに関する著作権は利用者に帰属するものとします。ただし、利用者が、第三者が著作権を有する画像データまたは第三者の著作物や肖像、個人情報が写り込んだ画像データを、無権限でアップロードした場合はこの限りではありません。
３. 利用者は、当社に対し、本サービスにアップロードした画像データおよび当該画像データを基に本サービスによって生成された画像データに関する著作権（著作権法２７条、２８条で定める権利を含みます）の利用を無償で許諾するものとし、当社はこれらの生成された画像データを本サービスの宣伝・広告に必要な限度で利用できるものとし、また、利用者は、これに対して、当社に対し、生成された画像データ著作者人格権を行使しないことに同意するものとします。

第７条	（利用料金）
本サービスの利用料は、当社が決定する期限までは無償とします。当社の任意の判断で有償に移行する場合には、当社は、本サービスサイトで、事前に明示いたします。

第８条	（本サービスの内容の変更、終了）
１. 当社は、当社の判断により、本サービスの内容を変更し、または提供を終了することができます。
２. 当社が本サービスの提供を終了する場合、当社は利用者に事前の通知を行いません。

第９条	（保証の否認及び免責）
１. 当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び、何らかの不具合が生じないことについて、明示的又は黙示的を問わず何ら保証いたしません。
２. 利用者が、第三者が著作権を有する画像データまたは第三者の著作物や肖像、個人情報が写り込んだ画像データを本サービスにアップロードしたことによって生じた紛争については、当社はいかなる責任も負わず、利用者が自身で解決するものとします。この紛争で、当社又は真田氏に損害が生じた場合には、利用者がその損害を補償するものとします。

第１０条（利用者情報の取扱い）
１. 本サービスの利用において、当社は、利用者に関する以下の情報を収集します。
(1) 本サービスにアップロードされた画像データおよび当該画像データを基に本サービスによって生成された画像データに関するに含まれる情報
(2)	リファラ
(3)	IPアドレス
(4)	サーバーアクセスログに関する情報
(5)	Cookie、ADID、IDFAその他の識別子
２. 当社は、上記の利用者に関する情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、登録利用者はこれに異議を申立てしないものとします。

第１１条（損害賠償）
利用者は、本規約に違反しまたは本サービスの利用に関連して当社に損害を与えた場合、当社に発生した損害（逸失利益および弁護士費用を含みます。）の一切を賠償するものとします。

第１２条（本規約等の変更）
当社は、当社が必要と認めた場合は、当社の判断で本規約を変更できるものとします。

第１３条（分離可能性）
本規約のいずれかの条項またはその一部が法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。

第１４条（準拠法及び管轄裁判所）
１. 本規約及び本サービス利用に関する事項の準拠法は日本法とします。
２. 本規約または本サービスの利用に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。

以上

`;
export default termsAndConditions;