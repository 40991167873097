import "./App.css";
import Webcam from "react-webcam";
import React, { useState, useRef, useCallback } from "react";
import Axios from 'axios';
import { Amplify, Auth } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports';
import ClickableImage from "./imageComponent"
import ScrollLock from "./scrollLock";
import LoadingSlider from "./Slider";
import NavigationBar from "./navigation-bar";
import Config from "./config";
import TermsAndConditions from "./TandCComponetns";
import Home from "./home";
import ServeImageComponents from "./serveImageComponents";
import ThanksPage from "./thanks";
import OutOfDate from "./outOfDate";
Amplify.configure(awsExports);


function WebcamImage() {
  const webcamRef = useRef(null);
  const [img, setImg] = useState(null);
  const [servImg, setServImg] = useState(null);
  const [devices, setDevices] = React.useState([]);
  const [targetDeviceNo, setTargetDevices] = React.useState(0);
  const [stage, setStage] = useState("capture");
  const [switched, setSwitched] = useState(false);
  // const [stage, setStage] = useState("capture");
  const [errorStatus, setErrorStatus] = useState({"status": 200, "message": ""});
  const [desireState, setDesireState] = useState({"targetStage": "", "sourceStages": []});

  const handleDevices = React.useCallback(
    (mediaDevices) => {
      console.log("handleDevices");
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput"));
    },
    [setDevices]
  );

  React.useEffect(
    () => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },
    [handleDevices]
  );

  React.useEffect(() => {
    // stage transition
    // console.log("stage transition a", desireState.targetStage, desireState.sourceStages, stage)
    if(desireState.targetStage !== "" && desireState.targetStage !== undefined && desireState.targetStage !== stage){
      // console.log("stage transition b", desireState.targetStage, desireState.sourceStages, stage);
      if(desireState.sourceStages.includes(stage)){
        if(desireState.targetStage === "connection error"){
          setImg(null);
          setServImg(null);
        }
        setStage(desireState.targetStage);
        setDesireState({"targetStage": "", "sourceStages": []});
      }
    }
  }, [desireState, stage]);

  const animationFinished = () => {
    console.log("animation finished");
    setDesireState({"targetStage": "loading", "sourceStages": ["shot"]});
  };

  const postImage = async (event, img, jwt) => {
      // console.log(event.x, event.y);
      // console.log(img);
      // jwtを使った認証
      const url = 'https://3xi5gtwtwb.execute-api.ap-northeast-1.amazonaws.com/alpha/web-inpaint';
      // const url = "http://10.10.0.123:8008/web-inpaint";
      var headers = {};
      if(jwt !== null){
        headers = {"Authorization": jwt};
      }
      Axios.post(url, {
      // Axios.post('/alpha/web-inpaint', {
        img_bytes: img,
        // v_min: 200,
        // s_max: 60,
        v_min: 254,
        s_max: 1,
        x_ratio: event.x,
        y_ratio: event.y,
        webapp: true,
      }, {
        headers: headers,
      }).then(function(res) {
        // alert(res.data.result);
        // console.log(imageSrc);
        setDesireState({"targetStage": "served", "sourceStages": ["loading", "shot"]});
        // if(stage === "shot" || stage === "loading"){
        //   setStage("served");
        // }
        // console.log("-----res-----")
        // console.log(res.data)
        // console.log(res.data.image_str);
        // console.log(err)
        // setImg(res.data.image_str);
        setServImg(res.data.image_str);
      }).catch(function (error) {
        if (error.response) {
          // リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコードで応答しました
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          setErrorStatus({"status": error.response.status, "message": error.response.data});
        } else if (error.request) {
          // リクエストは行われましたが、応答がありませんでした
          // `error.request` は、ブラウザでは XMLHttpRequest のインスタンスになり、
          // Node.js では http.ClientRequest のインスタンスになります。
          console.log(error.request);
          setErrorStatus({"status": 500, "message": "something wrong"});
        } else {
          // エラーをトリガーしたリクエストの設定中に何かが発生しました
          console.log('Error', error.message);
          setErrorStatus({"status": 500, "message": error.message});
        }
        // console.log(error.config);
        setDesireState({"targetStage": "connection error", "sourceStages": ["shot", "loading"]});
        // if(stage === "shot" || stage === "loading"){
        //   setStage("connection error");
        // }
      });

  }
  const handleClicked = (event) => {
    // setServImg(img);
    // setStage("served");

    if(Config.UserAuth === true){
      Auth.currentSession().then(function(sess) {
          const jwt = sess.getIdToken().getJwtToken()
          postImage(event, img, jwt);
        }
      );
    } else {
      postImage(event, img, null);
    }
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImg(imageSrc);
    setStage("shot");
  }, [webcamRef]);
  if (devices.length === 0) {
    return (
      <div className="Container w-screen flex justify-center items-center">
        <p>no devices</p>
      </div>
    )
  }

  // console.log(devices);
  // console.log(devices.length);
  // console.log(targetDeviceNo);
  var cameraNo = 0;
  if(targetDeviceNo >= devices.length){
    console.log()
    setTargetDevices(0);
    cameraNo = 0;
  } else {
    cameraNo = targetDeviceNo;
  }
  const videoConstraints = {
    width: 1080,
    height: 1080,
    // aspectRatio: 0.7,
    deviceId: devices[cameraNo].deviceId,
    // facingMode: "user",
  };
  console.log(stage);

  const recaptureButton = (
  <>
    <button onClick={() => {setImg(null); setServImg(null); setStage("capture")}} className="bg-black hover:bg-white text-white font-semibold hover:text-black py-2 px-4 border border-gray-300 rounded">撮影に戻る</button>
    <div className="h-20"></div>
  </>
  );

  // if (img === null) {
  if (stage === "capture") {
    // 日本時間の月曜日から金曜日の9:00-18:00のときのみ撮影可能にする
    const now = new Date();
    const japaneseNow = new Date(now.getTime() + 9 * 60 * 60 * 1000);
    const japaneseHour = japaneseNow.getUTCHours();

    if (japaneseNow.getDay() === 0 || japaneseNow.getDay() === 6) {
      return (
        <OutOfDate />
      );
    }
    if(japaneseHour < 9 || japaneseHour >= 18){
      return (
        <OutOfDate />
      );
    }
    // console.log("-------Img--------")
    var showCameraNo = "";
    if (switched === true) {
      showCameraNo = " (" + (targetDeviceNo + 1) + "/" + (devices.length) + ")";
    }
    // console.log(img)
    return (
      <div className="Container">
        <div className="w-screen flex justify-center items-center relative">
          <Webcam
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            audio={false}
            height={1080}
            width={1080}
            ref={webcamRef}
            mirrored={false}
            style={Config.ImageStyle}
          />
          <div className="absolute top-0">
            <button onClick={() => {
              navigator.mediaDevices.enumerateDevices().then(handleDevices);
              targetDeviceNo + 1 >= devices.length ? setTargetDevices(0) : setTargetDevices(targetDeviceNo + 1);
              setSwitched(true);
            }} className="bg-white text-black rounded-md shadow-2xl px-1 py-1">カメラ切り替え{showCameraNo}</button><br  />
          </div>
        </div>

        <br />
        <button onClick={capture} class="bg-black hover:bg-white text-white font-semibold hover:text-black py-2 px-4 border border-gray-300 rounded">写真を撮る</button>
        <div className="h-20"></div>
      </div>
    )
  } else if(stage === "shot") {
    // console.log("-------img--------")
    // console.log(img)
    return (
      <div className="Container">
        <div className="w-screen flex justify-center items-center">
          <ClickableImage handleClicked={handleClicked} src={img} animationFinished={animationFinished}/>
        </div>
        <br />
        {/* <button onClick={() => {setImg(null); setServImg(null); setStage("capture")}}>Recapture</button> */}
        {recaptureButton}
      </div>
    )
  } else if(stage === "loading") {
      // console.log("-------servimg--------")
      // console.log(servImg)
      return (
        <>
          <div className="Container">
            <LoadingSlider />
            {/* <button onClick={() => {setImg(null); setServImg(null); setStage("capture")}}>Recapture</button> */}
            <br />
            {recaptureButton}
          </div>
        </>
      );
  } else if(stage === "served") {
      // console.log("-------servimg--------")
      return (
      <div className="Container">
        <div className="w-screen flex justify-center items-center">
          <ServeImageComponents img={img} servImg={servImg}/>
        </div>
        <br />
        {recaptureButton}
      </div>
      );
  } else if(stage === "connection error"){
    return (
      <div>
        <div className="bg-blue-300">
          <p>{errorStatus.status}</p>
          <p>{errorStatus.message}</p>
        </div>
        <br />
        {recaptureButton}
      </div>
    )
  } else {
    console.log("stage is invalid");
  }

}

const MainApp = () => {
  return (
    <>
      <div classNmae="flex-col">
        <div className="text-white text-center">
          <div className="flex-1">
            <div className="w-screen flex justify-center">
              <div className="relative">
                <img src="logo.svg" alt="logo" className="h-20 m-4" style={{maxHeight: "5vh"}}/>
                {/* <p className="absolute bottom-0 right-0 text-ml text-white">α版</p> */}
              </div>
            </div>
            {/* <p>painter: {user.attributes.email}</p> */}
          </div>
          {/* <div>
            <button onClick={signOut} class="btn btn-dark" type="button">Sign out</button>
          </div> */}
          {/* <div className="flex justify-center"> */}
          <div className="items-center">
            <div>
              <WebcamImage />
            </div>
          </div>
        </div>
        <NavigationBar />
      </div>
      {/* <ScrollLock /> */}
    </>
  );
}


function App({ isPassedToWithAuthenticator, signOut, user }) {
  var savedStep = localStorage.getItem("step");
  if(savedStep === null){
    savedStep = "home";
  }
  const [step, setStep] = useState(savedStep);
  // if (!isPassedToWithAuthenticator) {
  //   throw new Error(`isPassedToWithAuthenticator was not provided`);
  // }
  const onNextOfHome = () => {
    setStep("TandC");
  };
  const onNextOfTandC = (isAgreed) => {
    if(isAgreed === true){
      setStep("main");
      console.log("setStep main")
    } else {
      setStep("home");
      console.log("setStep home");
    }
  };
  localStorage.setItem("step", step);

  console.log("step:" + step);
  if(false){
    return (
      // for end of service
      <ThanksPage />
    )
  } else if (step === "home") {
    return (
      <Home onNext={onNextOfHome}/>
    );
  } else if (step === "TandC") {
    return (
      <TermsAndConditions onNext={onNextOfTandC}/>
    );
  } else if (step === "main") {
    return (
      <MainApp />
    );
  } else {
    return <Home onNext={onNextOfHome}/>
  }
}

// export default withAuthenticator(App);
export default App;

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: false,
    },
  };
}
