
const Home = (props) => {
    const onNext = (event) => {
        if(props.onNext !== undefined){
            props.onNext();
        };
    };
    return (
        <div className="w-screen h-screen flex justify-center items-center">
            <div className="flex-col">
                <div className="flex justify-center">
                    <div className="relative">
                        <img src="logo.svg" alt="logo" className="w-80"/>
                        <p className="absolute bottom-0 right-0 text-2xl text-white">α版</p>
                    </div>
                </div>
                <div className="flex justify-center">
                    <h1 className="text-white text-3xl mt-3">作家の画風で世界を彩る</h1>
                </div>
                <div className="flex justify-center mt-10">
                    <button onClick={onNext} class="bg-black hover:bg-white text-white font-semibold hover:text-black py-2 px-4 border border-gray-300 rounded">Start</button>
                </div>
            </div>
        </div>
    );
};

export default Home;