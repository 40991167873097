import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, Mousewheel, Keyboard, EffectFade } from "swiper/modules";
import React, { useState } from "react";
import Config from "./config";

export const LoadingSlider = () => {
    const [init, setInit] = useState(true);
    var imgStyle = {
        widthMax: "1080px",
        heightMax: "1080px",
        // widthMax: "640px",
        // widthMax: "100%",
        // aspectRatio: "1 / 1",
        // position: "absolute",
        objectFit: "cover",
        // objectViewBox: "inset(0px 0px 32px 32px)",
        // aspectRatio: "1 / 1",
    }
    imgStyle = Object.assign(imgStyle, Config.ImageStyle);
    const classStyle = "w-screen flex justify-center items-center relative";
    const delayedSetInit = React.useCallback(() => {
        setInit(false);
    });

    var ImageArray = ["vl.gif", "adv-1.png", "adv-2.png", "adv-3.png"]
    if(init === true){
        delayedSetInit();
    } else {
        // 順番をシャッフルする
        ImageArray = ImageArray.sort(() => Math.random() - 0.5);
    }

    return (
        <>
            <Swiper
                className="mySwiper"
                slidesPerView={1}
                // spaceBetween={30}
                navigation={false}
                mousewheel={false}
                keyboard={false}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
                effect="fade"
                modules={[Navigation, Autoplay, Pagination, Mousewheel, Keyboard, EffectFade]}
            >
                <SwiperSlide>
                    <div className={classStyle}>
                        <div className="relative">
                            <img src={ImageArray[0]} alt="img1" style={imgStyle}></img>
                            <div class="animate-spin bg-white w-8 h-8 absolute top-2 left-2"></div>
                            <p className="absolute top-2 left-14 text-white text-2xl">生成中......</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classStyle}>
                        <div className="relative">
                            <img src={ImageArray[1]} alt="img1" style={imgStyle}></img>
                            <div class="animate-spin bg-white w-8 h-8 absolute top-2 left-2"></div>
                            <p className="absolute top-2 left-14 text-white text-2xl">生成中......</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classStyle}>
                        <div className="relative">
                            <img src={ImageArray[2]} alt="img1" style={imgStyle}></img>
                            <div class="animate-spin bg-white w-8 h-8 absolute top-2 left-2"></div>
                            <p className="absolute top-2 left-14 text-white text-2xl">生成中......</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={classStyle}>
                        <div className="relative">
                            <img src={ImageArray[3]} alt="img1" style={imgStyle}></img>
                            <div class="animate-spin bg-white w-8 h-8 absolute top-2 left-2"></div>
                            <p className="absolute top-2 left-14 text-white text-2xl">生成中......</p>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>

    );
};

export default LoadingSlider;